import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO() {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
                title
                author
                description
                siteUrl
                facebook
            }
          }
        }
      `}
      render={data => {
        return (
          <Helmet
            htmlAttributes={{
              lang: "en"
            }}
            title={data.site.siteMetadata.title}
            meta={
              [
                {
                  name: "url",
                  content: data.site.siteMetadata.siteUrl
                },
                {
                  name: "description",
                  content: data.site.siteMetadata.description
                },
                {
                  property: "og:title",
                  content: data.site.siteMetadata.title
                },
                {
                  property: "og:description",
                  content: data.site.siteMetadata.description
                },
                {
                  name: "facebook:creator",
                  content: data.site.siteMetadata.author
                },
                {
                  name: "facebook:url",
                  content: data.site.siteMetadata.facebook
                },
                {
                  name: "facebook:description",
                  content: data.site.siteMetadata.description
                }
              ]
            }
          />
        );
      }}
    />
  );
}

export default SEO;