import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import logo from "../../static/img/LogoFull.png"
import headerStyles from "./header.module.scss"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink} from 'reactstrap';

//const Header = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <Navbar className={headerStyles.navbar} color="light" light expand="lg">
          <NavbarBrand>
            <Link to="/">
              <img className={headerStyles.logo} src={logo} alt="Logo" />
            </Link>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse className="collapse" isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink>
                  <Link
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.activeNavItem}
                    to="/"
                  >
                    Home
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.activeNavItem}
                    to="/products"
                  >
                    Products
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.activeNavItem}
                    to="/about"
                  >
                    About
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.activeNavItem}
                    to="/contact"
                  >
                    Contact
                  </Link>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header
